<template>
    <div>
       <div class='container-fluid body-wrap'>
          <h4 class="account-settings">Account and settings</h4>

          <div>
            <div class='personal-security'>
               <!-- @click="options = 1" -->
               <h6 class="" :class="[options == 1 ? 'activeOption' : '']" >Personal Info</h6>
               <h6 :class="[options == 2 ? 'activeOption' : '']" @click="options = 2">Security</h6>
            </div>
            <div class="password-pin" v-if="!setpinPassword">
               <div class="pin-display">
                  <div>
                     <h6>App password</h6>
                     <h5>***********</h5>
                  </div>
                  <p @click="updateAppPassword = true; scrollToSection()">Change password</p>
               </div>

               <hr v-if="!updatePinPassword && !setpinPassword && !loadingProfile"/>
               <div class="pin-display" v-if="!updatePinPassword && !setpinPassword && !loadingProfile">
                  <div>
                     <h6>Transaction PIN</h6>
                     <h5>Your transaction PIN adds an extra layer of security for financial transactions. You’ll use this PIN authorize payments.</h5>
                  </div>
                  <!-- <p @click="setpin = true; setpinPassword = true;">Set PIN</p> -->
                  <p v-if="userData?.user?.secret == null" @click="setpin = true; setpinPassword = true;">Set PIN</p>
                  <p v-else @click="updatePin = true; updatePinPassword = true;">Update PIN</p>
               </div>
               <hr v-if="!loadingProfile"/>
            </div>

            <!-- To update app password starts -->
            <div class="updatePin" v-if="updateAppPassword" ref="passwordChange">
               <div class="pin-display">
                  <div>
                     <h6>Application password</h6>
                     <h5>Update your app. password.</h5>
                  </div>
               </div>
               <hr/>

               <div class="pin-holder">
                  <label>Current password <span>*</span></label>
                  <div class="form-group">
                     <input class="form-control input-field" @input="filterOldPassword" v-model="oldPassword" type="password" min="0"  name="oldPassword" id="oldPassword" placeholder="Current Password" />
                  </div>
               </div>
               <hr/>

               <div class="pin-holder">
                  <label>New password <span>*</span></label>
                  <div class="form-group">
                     <input class="form-control input-field" v-model="newPassword" type="password" min="0"  name="newPassword" id="newPassword" placeholder="New password" />
                  </div>
               </div>
               <hr/>

               <div class="pin-holder">
                  <label>Confirm new password <span>*</span></label>
                  <div class="form-group">
                     <input class="form-control input-field" v-model="confirmNewPassword" type="password" min="0"  name="confirmNewPassword" id="confirmNewPassword" placeholder="Confirm new password" />
                  </div>
               </div>
               <hr/>


               <div class="form-group button-set">
                  <button class="btn" @click="updateAppPassword = false;">Cancel</button>
                  <button class="btn" :disabled="updatingPassword" @click="updatePasswordNow()">{{updatingPassword ? 'updating password...' : 'Update password'}}</button>
               </div>
            </div>
            <!-- To update App password ends -->


            <div class="setting-up-pin" v-if="setpinPassword && setpin">
               <div class="pin-display">
                  <div>
                     <h6>Transaction PIN</h6>
                     <h5>Set up a transaction PIN to authorize payments securely.</h5>
                  </div>
               </div>
               <hr/>

               <div class="pin-holder">
                  <label>PIN <span>*</span></label>
                  <div class="form-group">
                     <input class="form-control input-field" v-model="pin" @input="filterNumbers" type="password" min="0" max="9999" maxlength="4" oninput="if(this.value.length > 4) this.value = this.value.slice(0, 4);" name="pin" id="pin" placeholder="Pin" />
                     <p class="options">Must be 4 digits</p>
                  </div>
               </div>
               <hr/>

               <div class="pin-holder">
                  <label>Confirm PIN <span>*</span></label>
                  <div class="form-group">
                     <input class="form-control input-field" v-model="confirmPin" type="password" min="0" max="9999" maxlength="4" oninput="if(this.value.length > 4) this.value = this.value.slice(0, 4);" name="confirmpin" id="confirmpin" placeholder="Confirm pin" />
                  </div>
               </div>
               <hr/>


               <div class="form-group button-set">
                  <button class="btn" @click="setpinPassword = false; setpin = false;">Cancel</button>
                  <button class="btn" :disabled="loadingPinSet" @click="setPinNow()">{{loadingPinSet ? 'Setting pin...' : 'Set PIN'}}</button>
               </div>
               
              
            </div>

            <div class="updatePin" v-if="updatePin && updatePinPassword">
               <div class="pin-display">
                  <div>
                     <h6>Transaction PIN</h6>
                     <h5>Update your PIN.</h5>
                  </div>
               </div>
               <hr/>

               <div class="pin-holder">
                  <label>Current PIN <span>*</span></label>
                  <div class="form-group">
                     <input class="form-control input-field" @input="filterOldPin" v-model="oldPin" type="password" min="0" max="9999" maxlength="4" oninput="if(this.value.length > 4) this.value = this.value.slice(0, 4);" name="currentpin" id="currentpin" placeholder="Current Pin" />
                  </div>
               </div>
               <hr/>

               <div class="pin-holder">
                  <label>New PIN <span>*</span></label>
                  <div class="form-group">
                     <input class="form-control input-field" v-model="newPin" type="password" min="0" max="9999" maxlength="4" oninput="if(this.value.length > 4) this.value = this.value.slice(0, 4);" name="newpin" id="newpin" placeholder="New pin" />
                     <p class="options">Must be 4 digits</p>
                  </div>
               </div>
               <hr/>

               <div class="pin-holder">
                  <label>Confirm new PIN <span>*</span></label>
                  <div class="form-group">
                     <input class="form-control input-field" v-model="confirmNewPin" type="password" min="0" max="9999" maxlength="4" oninput="if(this.value.length > 4) this.value = this.value.slice(0, 4);" name="confirmnewpin" id="confirmnewpin" placeholder="Confirm new pin" />
                  </div>
               </div>
               <hr/>


               <div class="form-group button-set">
                  <button class="btn" @click="updatePinPassword = false; updatePin = false;">Cancel</button>
                  <button class="btn" :disabled="loadingPinSet" @click="updatePinNow()">{{loadingPinSet ? 'updating pin...' : 'Update PIN'}}</button>
               </div>
            </div>
            <!-- <hr/> -->
          </div>
       </div>


    </div>
</template>



<script>

import transactionPin from "../services/transactionPin";
import userCredentials from "../services/userCredentials";

export default {
   name: "SettingsHome",
   // props: {
   //    emptyData: {
   //       type: Object,
   //       required: true,
   //    }
   // },
   data() {
      return {
         loading: false,
         loadingPinSet: false,
         options: 2,
         setpinPassword: false,
         loadingProfile: false,
         setpin: false,
         updatePinPassword: false,
         updatePin: false,
         updateAppPassword: "",
         updatingPassword: false,
         pin: "",
         confirmPin: "",
         oldPin: "",
         newPin: "",
         confirmNewPin: "",
         oldPassword: "",
         newPassword: "",
         confirmNewPassword: "",
         userData: "",
         userToken: ""
      }
   },
   created() {
      const user = this.$store.getters.getUser;
      this.userData = this.$store.getters.getUser;
      this.userID = user.id;
      this.userToken = user.token;
      this.getUserFullDetails();
   },
   mounted() {
      // this.getUserFullDetails();
   },
   methods: {
      scrollToSection() {
         this.$refs.passwordChange?.scrollIntoView({ behavior: "smooth" });
      },
      filterOldPassword() {
         this.oldPassword = this.oldPassword.replace(/\D/g, ""); // Remove non-numeric characters
      },
      filterNumbers() {
         this.pin = this.pin.replace(/\D/g, ""); // Remove non-numeric characters
      },
      filterOldPin() {
         this.oldPin = this.oldPin.replace(/\D/g, ""); // Remove non-numeric characters
      },
      async setPinNow() {
         this.loadingPinSet = true;
         if (this.pin == '') {
            this.loadingPinSet = false;
            return this.$toast.error("Enter pin");
         }
         if (this.confirmPin == '') {
            this.loadingPinSet = false;
            return this.$toast.error("Enter confirm pin");
         }
         if (this.pin != this.confirmPin) {
            this.loadingPinSet = false;
            return this.$toast.error("Pins don't match");
         }
         const body = {
            pin: this.pin,
         }
         try {
            await transactionPin.setTransactionPin(JSON.stringify(body)).then((res) => {
               // console.log("Pin setting", res);
               this.loadingPinSet = false;
               if (res.success) {
                  this.$toast.success("SUCCESS, TRANSACTION PIN SET");
                  this.setpinPassword = false;
                  this.setpin = false;
                  this.pin = "";
                  this.confirmPin = "",
                  this.getUserFullDetails();
               }
               if (res.error) {
                  return this.$toast.error(res.error);
               }
            }).catch((err) => {
               console.log(err);
               // this.loadingPinSet = false;
            });
         } finally {
            // console.log("closed");
         }
      },

      async updatePinNow() {
         this.loadingPinSet = true;
         if (this.oldPin == '') {
            this.loadingPinSet = false;
            return this.$toast.error("Enter old pin");
         }
         if (this.newPin == '') {
            this.loadingPinSet = false;
            return this.$toast.error("Enter new pin");
         }
         if (this.confirmNewPin == '') {
            this.loadingPinSet = false;
            return this.$toast.error("Enter confirm new pin");
         }
         if (this.newPin != this.confirmNewPin) {
            this.loadingPinSet = false;
            return this.$toast.error("Pins don't match");
         }
         const body = {
            pin: this.newPin,
            old_pin: this.oldPin,
         }
         try {
            await transactionPin.updateTransactionPin(JSON.stringify(body)).then((res) => {
               // console.log("Pin setting", res);
               this.loadingPinSet = false;
               if (res.success) {
                  this.$toast.success("SUCCESS, TRANSACTION PIN UPDATED");
                  this.updatePinPassword = false;
                  this.updatePin = false;
                  this.oldPin = "";
                  this.newPin = "";
                  this.confirmNewPin = "",
                  this.getUserFullDetails()
               }
               if (res.error) {
                  return this.$toast.error(res.error);
               }
            }).catch((err) => {
               console.log(err);
               // this.loadingPinSet = false;
            });
         } finally {
            // console.log("closed");
         }
      },
      async updatePasswordNow() {
         this.updatingPassword = true;
         if (this.oldPassword == '') {
            this.updatingPassword = false;
            return this.$toast.error("Enter old password");
         }
         if (this.newPassword == '') {
            this.updatingPassword = false;
            return this.$toast.error("Enter new password");
         }
         if (this.confirmNewPassword == '') {
            this.updatingPassword = false;
            return this.$toast.error("Enter confirm new password");
         }
         if (this.newPassword != this.confirmNewPassword) {
            this.updatingPassword = false;
            return this.$toast.error("Password don't match");
         }
         const body = {
            new_password: this.newPassword,
            old_password: this.oldPassword,
         }
         try {
            await transactionPin.updateAppPassword(JSON.stringify(body)).then((res) => {
               this.updatingPassword = false;
               if (res.success) {
                  this.$toast.success("SUCCESS, PASSWORD UPDATED");
                  this.updateAppPassword = false;
                  this.oldPassword = "";
                  this.newPassword = "";
                  this.confirmNewPassword = "",
                  this.getUserFullDetails()
               }
               if (res.error) {
                  return this.$toast.error(res.error);
               }
            }).catch((err) => {
               console.log(err);
               // this.loadingPinSet = false;
            });
         } finally {
            // console.log("closed");
         }
      },
      getUserFullDetails() {
         this.loadingProfile = true;
         userCredentials
         .userData(this.userID)
         .then(data => {
            // console.log("userrrrr", data)
            this.loadingProfile = false;
            if (data.success) {
               this.userData = data;
               data.user.token = this.userToken;
               this.$store.dispatch("saveUser", data.user);
            } else {
               this.$toast.error(data.error);
            }
         })
         .catch((err) => {
            this.loadingProfile = false;
            console.log(err);
            // this.$toast.error("please check your network and refresh the page");
         });
      },
   },
}
</script>



<style lang="scss" scoped>
   .body-wrap {
      background: #ffffff;
      padding: 40px 20px;
   }
   .account-settings {
      font-family: Lato;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0%;
      color: #2B3352;
      margin-bottom: 20px;
   }

   .personal-security {
      display: flex;
      justify-content: left;
      gap: 20px;
      border-bottom: 1px solid #E9EAEB;
      h6 {
         font-family: Lato;
         font-weight: 400;
         font-size: 16px;
         line-height: 24px;
         color: #555C74;
         margin-bottom: 0;
         padding-bottom: 10px;
         cursor: pointer;
      }
   }

   .activeOption {
      color: #0033EA !important;
      border-bottom: 1px solid #0033EA;
   }

   .pin-display {
      display: flex;
      justify-content: space-between;
      gap: 68px;
      margin-top: 25px;
      h6 {
         font-family: Lato;
         font-weight: 500;
         font-size: 18px;
         color: #2B3352;
      }
      h5 {
         font-family: Lato;
         font-weight: 400;
         font-size: 14px;
         color: #555C74;
         line-height: 24px;
      }
      p {
         color: #0033EA;
         font-family: Lato;
         font-weight: 400;
         font-size: 14px;
         white-space: nowrap;
         cursor: pointer;
      }
   }

   .pin-holder {
      display: flex;
      justify-content: space-between;
      .form-group {
         width: 77%;
      }
   }

   .input-field {
      height: 48px;
      outline: 0;
      box-shadow: none;
   }
   .options {
      font-family: Lato;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #555C74;
   }
   label {
      font-family: Lato;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0%;
      color: #2B3352;
   }
   .button-set {
      display: flex;
      justify-content: right;
      gap: 10px;
      button {
         font-family: Lato;
         font-weight: 400;
         font-size: 14px;
         box-shadow: none;
         outline: 0;
      }
      button:first-child {
         border: 1px solid #D5D7DA;
         color: #414651;
         
      }
      button:nth-child(2) {
         color: #ffffff;
         background: #0033EA;
      }
   }
   @media screen and (max-width: 599px) { 
      .pin-holder {
         display: block;
      }
      .form-group {
         width: 100% !important;
      }
   }
</style>